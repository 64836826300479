<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-container>
      <v-toolbar class="primary" tag="div" flat dark tile>
        <div class="text-h6">Clients Import</div>
        <v-spacer></v-spacer>
      </v-toolbar>
      <template>
        <v-stepper v-model="fase" vertical>
          <v-stepper-step :complete="fase > 1" step="1">
            Upload File
            <small>Files format: xls, csv , xlsx</small>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-file-input
              v-model="file"
              accept=".xls,.csv,.xlsx"
              show-size
              truncate-length="15"
              label="Select File"
            ></v-file-input>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading || file == null"
              @click="firstStep"
            >
              Continue
            </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="fase > 2" step="2">
            Data to match
            <small>Select files to match</small>
          </v-stepper-step>

          <v-stepper-content step="2">
            <template v-if="headersData.length != 0">
              <v-row class="pa-1">
                <v-col cols="12" sm="6">
                  <v-select
                    :items="headersData"
                    v-model="headers.name"
                    label="Name *"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.lastName"
                    label="Last Name *"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.dob"
                    label="DOB *"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.email"
                    label="Email"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.phone"
                    label="Phone *"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.policyNumber"
                    label="Policy Number"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.monthlyPay"
                    label="Monthly pay"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.policyPlan"
                    label="Metal plan"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.binderDay"
                    label="Binder pay"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.members"
                    label="Members"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <div class="d-flex">
                    <v-select
                      :items="companies"
                      v-model="headers.companyId"
                      label="Company *"
                      :loading="loadingRefer"
                      item-text="name"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    ></v-select>
                    <v-select
                      :items="headersData"
                      v-model="headers.companyState"
                      label="Company State"
                      class="ml-1"
                      outlined
                      clearable
                      dense
                    ></v-select>
                  </div>

                  <v-select
                    v-model="headers.preferedLanguage"
                    label="Preferred Language"
                    :items="headersData"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="workers"
                    v-model="headers.agentUuid"
                    label="Agent"
                    :loading="loadingEmployes"
                    item-text="fullname"
                    item-value="uuid"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="workers"
                    v-model="headers.creatorUuid"
                    label="Creator *"
                    :loading="loadingEmployes"
                    item-text="fullname"
                    item-value="uuid"
                    outlined
                    clearable
                    dense
                  ></v-select>
                </v-col>
                <template v-if="firstData != null">
                  <v-col class="d-flex flex-column" cols="12" sm="6">
                    <v-text-field
                      label="Name"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.name)"
                    ></v-text-field>
                    <v-text-field
                      label="Last Name"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.lastName)"
                    ></v-text-field>
                    <v-text-field
                      label="DOB"
                      disabled
                      :class="
                        getDataDob(headers.dob) == 'Invalid date'
                          ? 'errordate'
                          : ''
                      "
                      outlined
                      dense
                      :value="getDataDob(headers.dob)"
                    ></v-text-field>
                    <v-text-field
                      label="Email"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.email)"
                    ></v-text-field>
                    <v-text-field
                      label="Phone"
                      disabled
                      outlined
                      dense
                      :value="getPhone(headers.phone)"
                    ></v-text-field>
                    <v-text-field
                      label="Policy Number"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.policyNumber)"
                    ></v-text-field>
                    <v-text-field
                      label="Monthly pay"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.monthlyPay)"
                    ></v-text-field>
                    <v-text-field
                      label="Metal plan"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.policyPlan)"
                    ></v-text-field>
                    <v-text-field
                      label="Binder pay"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.binderDay)"
                    ></v-text-field>
                    <v-text-field
                      label="Members"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.members)"
                    ></v-text-field>
                    <div class="d-flex">
                      <v-text-field
                        label="Company"
                        disabled
                        outlined
                        dense
                        :value="getCompanySelect(headers.companyId)"
                      ></v-text-field>
                      <v-text-field
                        label="Company State"
                        class="ml-1"
                        disabled
                        outlined
                        dense
                        :value="getData(headers.companyState)"
                      ></v-text-field>
                    </div>

                    <v-text-field
                      label="Preferred Language"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.preferedLanguage)"
                    ></v-text-field>
                    <v-text-field
                      label="Agent"
                      disabled
                      outlined
                      dense
                      :value="getEmployeeSelect(headers.agentUuid)"
                    ></v-text-field>
                    <v-text-field
                      label="Creator"
                      disabled
                      outlined
                      dense
                      :value="getEmployeeSelect(headers.creatorUuid)"
                    ></v-text-field>
                  </v-col>
                </template>
              </v-row>

              <v-alert
                outlined
                type="error"
                :value="!validMatch || !validDate || !validPhone"
              >
                {{ !validDate ? "Invalid DOB, " : "" }}
                {{ !validPhone ? "Invalid Phone, " : "" }}
                {{ !validMatch ? "All fields with ( * ) are required" : "" }}
              </v-alert>
            </template>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading || !validMatch"
              @click="secondStep"
            >
              Confirm
            </v-btn>
            <v-btn
              color="error"
              class="ml-1"
              :loading="loading"
              :disabled="loading"
              @click="resetAll"
            >
              Cancel
            </v-btn>
          </v-stepper-content>

          <v-stepper-step step="3" :complete="fase == 3">
            Import successful
          </v-stepper-step>
          <v-stepper-content step="3">
            <div>
              <label for="">Existing: </label>
              {{ configResult ? configResult.existing : "" }}
            </div>
            <div class="my-2">
              <label for="">Saved: </label>
              {{ configResult ? configResult.saved : "" }}
            </div>
            <v-btn color="primary" @click="resetAll"
              ><v-icon class="mr-1">mdi-cloud-upload</v-icon> import another
            </v-btn>
            <v-btn class="mx-2" color="warning" @click="backclients">
              <v-icon>mdi-exit-to-app</v-icon> Exit
            </v-btn>
          </v-stepper-content>
        </v-stepper>
      </template>
    </v-container>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import cleanObject from "@/utils/cleanObject";
import { notifyError } from "@/components/Notification";
import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {
  name: "import-client",
  data() {
    return {
      loadingCompanyStates: false,
      companyStates: [],
      loading: false,
      headersData: [],
      loadingEmployes: false,
      firstData: null,
      lastResult: null,
      configResult: null,
      file: null,
      fase: 1,

      headers: {
        creatorUuid: null,
        agentUuid: null,
        companyId: null,
        name: null,
        lastName: null,
        phone: null,
        email: null,
        policyPlan: null,
        members: null,
        dob: null,
        monthlyPay: null,
        binderDay: null,
        companyState: null,
        preferedLanguage: null,
        policyNumber: null,
      },
      langs: [
        {
          label: "English",
          value: "ENGLISH",
        },
        {
          label: "Spanish",
          value: "SPANISH",
        },
      ],
    };
  },
  computed: {
    ...mapState("crmSettingsModule", ["loadingRefer", "companies"]),
    ...mapState("crmEmployeeModule", ["workers"]),
    validDate() {
      let temp = "";

      if (
        this.headers.dob != null &&
        this.firstData[this.headers.dob] != undefined
      ) {
        temp = this.firstData[this.headers.dob].toString();
      }
      if (
        this.headers.dob != null &&
        temp.includes("T") &&
        temp.includes(":") &&
        temp.includes("-") &&
        temp.includes("Z")
      ) {
        return true;
      }
      return false;
    },
    validPhone() {
      const path = /^[1-9][0-9]{9}$/;
      let temp = this.firstData[this.headers.phone];
      if (temp == undefined || temp == null) {
        return false;
      }
      temp = temp.toString().replace(/\D+/g, "");

      if (temp[0] == "1") {
        temp = temp.replace("1", "");
      } else {
        temp = temp;
      }

      if (this.headers.phone != null && path.test(temp)) {
        return true;
      }
      return false;
    },
    validMatch() {
      if (
        this.headers.name != null &&
        this.headers.lastName != null &&
        this.headers.creatorUuid != null &&
        this.headers.companyId != null &&
        this.validDate &&
        this.validPhone
      ) {
        return true;
      }
      return false;
    },
  },

  methods: {
    ...mapActions("crmSettingsModule", ["actListCompaniesNom"]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    getData(value) {
      return this.firstData[value];
    },
    getPhone(value) {
      if (value) {
        let val = this.firstData[value].toString().replace(/\D+/g, "");
        if (val[0] != "1") {
          return "+1" + val;
        }
        return "+" + val;
      }
    },
    getDataDob(value) {
      let temp = this.firstData[value];
      if (value == null || temp == undefined) {
        return "Invalid date";
      }
      temp = temp.toString();
      if (
        temp.includes("T") &&
        temp.includes(":") &&
        temp.includes("-") &&
        temp.includes("Z")
      ) {
        const dat = temp.split("T")[0];
        const d = dat.split("-")[2];
        const m = dat.split("-")[1];
        const y = dat.split("-")[0];
        return `${m}/${d}/${y}`;
      }

      return "Invalid date";
    },

    getCompanySelect(value) {
      return this.companies.find((c) => c.id == value)?.name;
    },

    getEmployeeSelect(value) {
      return this.workers.find((c) => c.uuid == value)?.fullname;
    },
    firstStep() {
      this.loading = true;
      const formDatafile = new FormData();

      formDatafile.append("file", this.file);

      getAPI
        .post("/social/decodeFilePreview", formDatafile)
        .then((res) => {
          this.loading = false;
          this.headersData = res.data.headers;
          this.firstData = res.data.first;
          this.fase = 2;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    secondStep() {
      this.loading = true;
      let body = Object.assign({}, this.headers);

      body = cleanObject(body);
      if (body.companyId) {
        body.companyId = Number(body.companyId);
      }

      const formDatafile = new FormData();

      formDatafile.append("file", this.file);

      Object.entries(body).forEach(([key, value]) => {
        formDatafile.append(key, value);
      });

      getAPI
        .post("/patient/importClients", formDatafile)
        .then((res) => {
          this.loading = false;
          this.configResult = res.data;
          this.fase = 3;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    getCompanyState(id) {
      this.loadingCompanyStates = true;
      getAPI
        .post("/company-state-price/filterList", { companyId: id })
        .then((res) => {
          this.loadingCompanyStates = false;
          this.companyStates = res.data;
        })
        .catch((error) => {
          this.loadingCompanyStates = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    backclients() {
      this.configResult = null;
      this.$router.push("/clients");
    },

    resetAll() {
      this.file = null;
      this.loading = false;
      this.fase = 1;
      this.headersData = [];
      this.firstData = null;
      this.lastResult = null;
      this.configResult = null;

      this.headers = {
        creatorUuid: null,
        agentUuid: null,
        companyId: null,
        name: null,
        lastName: null,
        phone: null,
        email: null,
        policyPlan: null,
        dob: null,
        monthlyPay: null,
        binderDay: null,
      };
    },
  },
  async mounted() {
    this.actListCompaniesNom();
    this.loadingEmployes = true;
    await this.actGetEmployees();
    this.loadingEmployes = false;
  },
};
</script>
<style lang="scss" scoped>
.errordate {
  color: red !important;
}
</style>
